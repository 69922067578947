@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
  width: 100%;
}

.tableContainer {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  background: $element-background-primary;

  &_title {
    font-weight: 600;
    text-transform: uppercase;
    color: $text-primary-color;
    @include font-2xl;
  }

  &_subtitle {
    margin-top: 20px !important;
    color: $primary-color;
    font-weight: 500;
    @include font-xl;
  }
}

.tableWrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
  }

  thead {
    height: 46px;
    background: $element-background-secondary;

    th {
      padding: 0 20px;
      border: 1px solid $delimitter-line-color;
      border-left-width: 0;
      border-right-width: 0;
      box-sizing: border-box;
      color: $text-primary-color;
      text-transform: uppercase;
      font-weight: 600;
      white-space: nowrap;
      @include font-lg;

      &:first-of-type {
        border-left-width: 1px;
      }

      &:last-of-type {
        border-right-width: 1px;
      }
    }
  }

  tbody {
    tr {
      height: 60px;
      background: $background-color;
    }

    td {
      height: 60px;
      padding: 20px;
      border: 1px solid $delimitter-line-color;
      border-left-width: 0;
      border-right-width: 0;
      box-sizing: border-box;
      color: $subtext-color;
      white-space: nowrap;
      @include font-lg;

      p span {
        font-weight: 600;
      }

      &:first-of-type {
        border-left-width: 1px;
      }

      &:last-of-type {
        border-right-width: 1px;
      }
    }
  }
}
