@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.content {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}

.right {
  gap: 20px;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  @include font-2xl;
}

.itineraryButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.subtitle {
  text-transform: uppercase;
  font-weight: 600;
  @include font-lg;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.footerNote {
  color: $subtext-color;
  font-size: 12px;
  margin-top: 20px;
}

@include tablet {
  .content {
    display: grid;
    gap: 40px;
    grid-template-columns: 3fr 1fr;
  }

  .title {
    @include font-3xl;
  }

  .button {
    width: unset;
    padding: 0 40px;
  }
}
