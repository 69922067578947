@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  position: fixed;
  top: 0;
  width: 100%;
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
  background: $header-background;
  color: $header-text;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.nav {
  display: none;
  gap: 40px;
}

.buttonLink {
  text-transform: uppercase;
  text-decoration: underline;
  color: $text-secondary-color;
  cursor: pointer;
  font-weight: 600;
  opacity: 0.5;
  @include transition(opacity);

  &:hover {
    opacity: 1;
  }
}

.link {
  text-transform: uppercase;
  color: $text-secondary-color;
  cursor: pointer;
  font-weight: 600;
}

.menuIcon {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 40px;
  opacity: 0;
  pointer-events: none;

  svg {
    min-width: 28px;
    min-height: 28px;
    transform: skew(-45deg) scale(0.2);
    transition: all 0.3s ease-in-out;
  }

  &_visible {
    opacity: 1;
    pointer-events: all;

    svg {
      transform: skew(0deg) scale(1);
    }
  }
}

@include laptop {
  .nav {
    display: flex;
  }

  .menuIcon {
    display: none;
  }
}
