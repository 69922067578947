@import "../../../../../styles/mixins.scss";

.submitButton {
  margin-top: 20px;
}

@include tablet {
  .submitButton {
    width: unset;
    padding: 0 80px;
  }
}
