.carousel {
  width: 100%;
}

.carousel-inner,
.carousel-item {
  height: 100%;
}

.carousel-item.active {
  display: flex;
}
