@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.imagesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.images {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.map {
  width: 100%;
}

.name {
  text-transform: uppercase;
  @include font-2xl;
}

.details {
  @include font-lg;

  tr {
    td:first-of-type {
      padding-right: 20px;
      font-weight: 600;
    }
  }
}

.price {
  display: flex;
  flex-direction: column;

  &_from {
    @include font-lg;
  }

  &_value {
    @include font-2xl;
    font-weight: 600;
  }
}

.itinerary {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.itineraryTitle {
  font-weight: 600;
  @include font-lg;
}

@include tablet {
  .name {
    @include font-4xl;
  }
}
