.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 100%;
  max-width: 235px;
}
