.container {
  width: 100%;
  height: unset;
}

.arrowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 8px;
  background-color: rgba($color: #ffffff, $alpha: 0.5);
}

.slide {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
}
