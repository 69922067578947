@import "../../../styles/variables.scss";

.container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $footer-background;
  color: $footer-text;
}
