@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.fieldContainer {
  width: min-content;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $element-background-secondary;
}

.button {
  width: 46px;
  height: 100%;
  border: 0;
  outline: 0;
  background: $text-secondary-color;
}

.input {
  width: 80px;
  height: 100%;
  border: 0;
  outline: 0;
  background: $text-secondary-color;
  border-left: 1px solid $element-background-secondary;
  border-right: 1px solid $element-background-secondary;
  color: $text-primary-color;
  text-align: center;
  font-weight: 600;
  @include font-2xl;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.error {
  height: 24px;
  display: inline-block;
  color: $error-color;
}
