@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background: $element-background-secondary;
  color: $text-primary-color;
  cursor: pointer;
  @include transition(transform);

  &:hover {
    transform: scale(1.01);
  }
}

.body {
  width: 100%;
  min-height: 0;
  max-height: 0;
  padding: 20px;
  box-sizing: border-box;
  background: $element-background-primary;
  overflow: hidden;
  @include transition(min-height, max-height, padding);
}
