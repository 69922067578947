@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.label {
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-lg;
}

.picker {
  height: 66px;
  box-sizing: border-box;
  color: $input-text-color !important;
  background: $input-background-color !important;
  border-radius: 0 !important;
  border: 1px solid $element-background-secondary;
  outline: 0 !important;
  padding: 0 10px !important;
  transition: all 0.3s ease-in-out !important;
  cursor: default;
  @include font-lg;

  &_error {
    border: 1px solid $error-color !important;
  }

  &::placeholder {
    color: $input-placeholder-color !important;
    transition: all 0.3s ease-in-out !important;
  }
}

.error {
  height: 24px;
  display: inline-block;
  color: $error-color;
}
