@import "../../../styles/variables.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content {
  width: 100%;
  display: grid;
  flex: 1;
}
