@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
  width: 100%;
  position: relative;
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: $mobile-sm-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 20px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.title {
  width: 100%;
  text-transform: uppercase;
  color: $text-secondary-color;
  font-weight: 300;
  @include font-3xl;
}

.subtitle {
  width: 100%;
  color: $text-secondary-color;
  @include font-lg;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: $background-fade;
  opacity: 0.9;
  pointer-events: none;
}

@include mobile-md {
  .content {
    max-width: $mobile-base-width;
  }
}

@include mobile-lg {
  .content {
    max-width: $mobile-md-width;
  }
}
