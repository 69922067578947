@import "./mixins.scss";
@import "./variables.scss";

* {
  font-family: "Montserrat", sans-serif;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  color: $text-primary-color !important;
  box-sizing: border-box;
  background-color: $background-color;
  @include font-base;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection,
p::selection,
span::selection,
input::selection {
  background-color: $primary-color;
  color: $element-background-secondary;
}

#root {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-color;
}
