@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;

  &_active {
    max-height: 100vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }
}

.modal {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  flex-grow: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  z-index: 1000;
  padding: 40px 0;
  box-sizing: border-box;
  overflow: hidden;
  pointer-events: none;
  backdrop-filter: none;
  background-color: transparent;
  @include transition(background-color, backdrop-filter, padding);

  &_active {
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    pointer-events: all;
    backdrop-filter: blur(4px);
  }
}

.content {
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background: $background-color;
  opacity: 0;
  padding: 20px;
  box-sizing: border-box;
  transform: translateY(10px) scale(0.96);
  @include transition(opacity, transform, width);

  &_sm,
  &_md,
  &_lg {
    width: 375px;
  }

  &_active {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-weight: 300;
  color: $text-primary-color;
  @include transition(font-size);
  @include font-xl;
}

.closeIcon {
  cursor: pointer;
  @include transition(transform);

  &:hover {
    transform: rotate(-90deg);
  }
}

@include mobile-lg {
  .modal {
    padding: 100px 0;
  }

  .content {
    padding: 40px;

    &_sm {
      width: 500px;
    }

    &_md {
      width: 600px;
    }

    &_lg {
      width: 700px;
    }
  }

  .headerTitle {
    @include font-2xl;
  }
}

@include tablet {
  .content {
    &_sm {
      width: 600px;
    }

    &_md {
      width: 750px;
    }

    &_lg {
      width: 900px;
    }
  }
}

@include laptop {
  .content {
    &_sm {
      width: 700px;
    }

    &_md {
      width: 900px;
    }

    &_lg {
      width: 1100px;
    }
  }
}

@include desktop {
  .content {
    &_sm {
      width: 1000px;
    }

    &_md {
      width: 1200px;
    }

    &_lg {
      width: 1400px;
    }
  }
}
