@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.greetingContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  @include font-3xl;
}

.subTitle {
  width: 100%;
  font-weight: 400;
  @include font-xl;
}

.tiles {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 40px;
}

.tile {
  width: 100%;
  height: 430px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  @include transition(transform);

  &_disabled {
    cursor: not-allowed;
  }

  &_headerText {
    color: $text-secondary-color;
    text-transform: uppercase;
    font-weight: 600;
    @include font-xl;
  }

  &_bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    z-index: 2;
  }

  &_text {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
  }

  &_title,
  &_description {
    color: $text-secondary-color;
  }

  &_title {
    text-transform: uppercase;
    font-weight: 600;
    @include font-2xl;
  }

  &_description {
    font-weight: 400;
    @include font-lg;
  }

  &_playContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    svg {
      width: 24px;
      height: 24px;

      path {
        &:first-of-type {
          stroke: $text-secondary-color;
          fill: none;
        }

        &:last-of-type {
          fill: $text-secondary-color;
        }
      }
    }
  }

  &_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;

    &_teal {
      background: $primary-color;
      opacity: 0.65;
    }

    &_lilac {
      background: $text-primary-color;
      opacity: 0.65;
    }
  }

  &:hover:not(&_disabled) {
    transform: scale(1.005);
  }
}

@include tablet {
  .title {
    @include font-4xl;
  }
}

@include laptop {
  .tiles {
    grid-template-columns: repeat(3, 1fr);
  }
}
