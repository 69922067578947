@import "../../../../../styles/variables.scss";

.container {
  max-width: 300px;
  display: flex;
  align-items: center;
  padding: 6px 6px 6px 40px;
  box-sizing: border-box;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  z-index: 1001;
  opacity: 0;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  will-change: transform opacity;

  &_visible {
    transform: translateX(-50%) scale(1);
    opacity: 1;
    pointer-events: all;
  }

  &_success {
    background: $primary-color;
  }

  &_error {
    background: $error-color;
  }
}

.message {
  color: $text-secondary-color;
  text-align: center;
  font-weight: 600;
  margin-right: 10px;
}
