@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  @include font-2xl;
}

.results {
  display: grid;
  gap: 20px;
}

.item {
  background: $text-secondary-color;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.05);
}

.image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 26px 20px;
  border-top: 4px solid $element-background-secondary;
  box-sizing: border-box;
}

.name {
  color: $text-primary-color;
  text-transform: uppercase;
  font-weight: 600;
  @include font-xl;
}

.details {
  @include font-lg;

  tr {
    td:first-of-type {
      padding-right: 20px;
      font-weight: 600;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 26px;
  box-sizing: border-box;
}

.price {
  display: flex;
  flex-direction: column;

  &_from {
    @include font-lg;
  }

  &_value {
    font-weight: 600;
    @include font-xl;
  }
}

.button {
  width: unset;
  padding: 0 40px;
  height: 66px;
  border: 0;
  text-align: center;
  outline: 0;
  text-transform: uppercase;
  color: $text-secondary-color;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 600;
  @include transition(box-shadow, background-color, opacity, text-decoration);
  @include font-lg;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  }

  &:active {
    background-color: $secondary-color;
    box-shadow: none;
  }
}

@include tablet {
  .title {
    @include font-4xl;
  }

  .results {
    grid-template-columns: repeat(2, 1fr);
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .name {
    @include font-2xl;
  }

  .button {
    padding: 0 80px;
  }
}
