@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-lg;
}

.fieldContainer {
  position: relative;
}

.field {
  width: 100%;
  height: 66px;
  box-sizing: border-box;
  color: $input_text_color;
  background: $input-background-color;
  border: 1px solid $element-background-secondary;
  outline: 0;
  padding: 0 10px;
  transition: all 0.3s ease-in-out;
  @include font-lg;

  &_error {
    border: 1px solid $error-color !important;
  }

  &::placeholder {
    color: $input-placeholder-color;
    transition: all 0.3s ease-in-out;
  }

  &:disabled {
    background: $element-background-secondary;
    cursor: not-allowed;
  }
}

.results {
  width: 100%;
  max-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 66px;
  z-index: 1;
  overflow-y: scroll;
  background: $background-color;
  transition: all 0.3s ease-in-out;

  &_visible {
    max-height: 200px;
  }
}

.loader {
  margin: 24px 0;
}

.resultsItem {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: $primary-color;
  color: $text-secondary-color;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $text-secondary-color;
    color: $primary-color;
  }
}

.chosen {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  box-sizing: border-box;

  &_visible {
    padding: 10px 0;
  }
}

.chosenItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: $secondary-color;
  color: $text-secondary-color;
  padding: 0 10px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $primary-color;
  }
}

.closeIcon {
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
  cursor: pointer;

  path {
    fill: $text-secondary-color;
  }
}

.error {
  height: 24px;
  display: inline-block;
  color: $error-color;
}
