@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@include tablet {
  .container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
  }
}
