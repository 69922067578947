@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.button {
  width: 100%;
  height: 66px;
  border: 0;
  text-align: center;
  outline: 0;
  text-transform: uppercase;
  cursor: pointer;
  color: $text-secondary-color;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 600;
  @include transition(box-shadow, background-color, opacity, text-decoration);
  @include font-lg;

  &_secondary {
    background-color: $element-background-secondary;
    color: $text-primary-color;
  }

  &_link {
    width: unset;
    height: unset;
    color: $text-primary-color;
    background-color: transparent;
    text-decoration: underline;
    font-weight: 500;
    padding: 0;
    @include font-base;

    &:hover {
      box-shadow: none !important;
      text-decoration: none;
    }

    &:active {
      background-color: transparent !important;
    }
  }

  &_withIcon {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
  }

  &:hover:not(&:disabled) {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  }

  &:active:not(&:disabled) {
    background-color: $secondary-color;
    box-shadow: none;
  }

  &:disabled {
    background-color: $secondary-color;
    cursor: not-allowed;
  }
}
