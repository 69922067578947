@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  position: relative;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left__value,
.slider__right__value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  // position: sticky;
  background-color: $element-background-secondary;
  width: 100%;
  z-index: 2;
}

.slider__range {
  background-color: $primary-color;
  z-index: 2;
  pointer-events: none;
}

.slider__left__value,
.slider__right__value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left__value {
  left: 6px;
}

.slider__right__value {
  right: -4px;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb__left {
  z-index: 3;
}

.thumb__right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: $primary-color;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px $text-secondary-color;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: $primary-color;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px $text-secondary-color;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
