@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: $side-menu-width;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 2;
  left: -$side-menu-width;
  transition: left 0.3s ease-in-out;

  &_visible {
    left: 0;
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;
  z-index: 4;
  background-color: $text-primary-color;
  padding-top: calc($header-height + 20px);

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 40px;
  box-sizing: border-box;
}

.buttonLink {
  text-transform: uppercase;
  text-decoration: underline;
  color: $text-secondary-color;
  cursor: pointer;
  font-weight: 600;
  opacity: 0.5;
  @include transition(opacity);

  &:hover {
    opacity: 1;
  }
}

.link {
  text-transform: uppercase;
  color: $text-secondary-color;
  cursor: pointer;
  font-weight: 600;
}

.backdrop {
  width: 100vw;
  height: calc(100vh - $header-height);
  position: fixed;
  top: $header-height;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  pointer-events: none;
  background-color: transparent;
  backdrop-filter: none;
  transition: all 0.3s ease-in-out;

  &_visible {
    pointer-events: all;
    backdrop-filter: blur(4px);
    background-color: rgba($color: $text-primary-color, $alpha: 0.5);
  }
}

@include laptop {
  .container {
    top: 0;
    left: 0;
    display: none;
  }
}
