@import "../../../styles/variables.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}

.content {
  width: 100%;
  max-width: $laptop-width;
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 0 40px 40px;
  box-sizing: border-box;
}
