.page-item .page-link {
  color: var(--primary-color);
}

.page-item.active .page-link {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.more-button {
  width: 100%;
  height: 70px;
  border: 0;
  text-align: center;
  outline: 0;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--text-secondary-color);
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 600;
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  will-change: box-shadow, background-color, opacity;
  font-size: 16px !important;
  line-height: 24px !important;
}
