@import "../../../styles/mixins.scss";

.container {
  text-transform: uppercase;
  padding: 20px 0 40px;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.text {
  margin-top: 20px !important;
}

@include tablet {
  .container {
    padding: 40px 0 60px;
  }
}
