@import "../../../styles/variables.scss";

.container {
  text-decoration: none;
  color: $primary-color;
}

.primary {
  font-weight: 600;
}
