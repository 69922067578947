@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.container {
  width: 100%;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input {
  label,
  input,
  input::placeholder {
    color: $input-placeholder-color;
  }

  label {
    color: $text-primary-color;
    font-weight: 500;
  }

  input,
  &[class^="CustomSelect_field"] {
    border: 2px solid $element-background-secondary !important;
  }
}

@include tablet {
  .submitButton {
    width: unset;
    padding: 0 80px;
  }

  .inputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    &Date {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
}
