@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &_disabled {
    cursor: not-allowed;
    pointer-events: all;
  }

  &_disabled * {
    pointer-events: none;
  }
}

.label {
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-lg;
}

.fieldContainer {
  width: 100%;
  position: relative;
}

.field {
  position: relative;
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: $input-background-color;
  border: 1px solid $element-background-secondary;
  outline: 0;
  padding: 0 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  @include font-lg;

  &_error {
    border: 1px solid $error-color;
  }

  .container.container_disabled & {
    background: #b0c4de;
    cursor: not-allowed;
  }
}

.value {
  color: $input_text_color;

  &::after {
    content: "\25BC";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: $element-background-secondary;
  }

  &_visible {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.placeholder {
  color: $input-placeholder-color;
}

.items {
  width: 100%;
  max-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 66px;
  z-index: 1;
  overflow-y: scroll;
  background: $background-color;
  transition: all 0.3s ease-in-out;

  &_visible {
    max-height: 200px;
  }
}

.item {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: $primary-color;
  color: $text-secondary-color;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &_chosen {
    background: $text-secondary-color;
    color: $primary-color;
  }

  &:hover {
    background: $text-secondary-color;
    color: $primary-color;
  }
}

.error {
  height: 24px;
  display: inline-block;
  color: $error-color;
}
