@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  max-width: $mobile-base-width;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.button {
  height: 50px;
  @include font-base;

  svg {
    width: 16px;
    height: 16px;
    fill: $text-secondary-color;
  }

  &.activeButton {
    background-color: $secondary-color;
  }
}

.rangeSliderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px 0 20px;
}

.labelRangePrice {
  @include font-base;
  color: $input_text_color;
  font-weight: 600;
}
