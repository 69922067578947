@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.link {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  text-decoration: none;
  color: $text-secondary-color;
  opacity: 0.5;
  @include transition(opacity);

  &:hover,
  &_active {
    opacity: 1;
  }
}
