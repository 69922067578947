@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: min-content;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.label {
  cursor: pointer;
  color: $text-primary-color;
  text-transform: uppercase;
  white-space: nowrap;
  @include font-lg;
}

.field {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  margin: 0;
  cursor: pointer;

  border: 2px solid $primary-color;

  &:before {
    background-color: $primary-color;
  }

  &:before {
    width: 10px;
    height: 10px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0;
    @include transition(opacity);
  }

  &:checked:before {
    opacity: 1;
  }
}
