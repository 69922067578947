@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  @include font-2xl;
}

.markets {
  display: grid;
  gap: 20px;
}

.market {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid $primary-color;
  padding: 40px 0;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &_disabled {
    border-color: $input-placeholder-color;
    cursor: not-allowed;

    .marketName,
    .from,
    .price {
      color: $input-placeholder-color;
    }
  }

  &_active,
  &:hover:not(&_disabled) {
    background: $primary-color;
    color: $text-secondary-color;

    .marketName,
    .from,
    .price {
      color: $text-secondary-color;
    }
  }
}

.marketName {
  color: $primary-color;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  @include font-lg;
}

.from {
  color: $input_text_color;
  transition: all 0.3s ease-in-out;
  @include font-lg;
}

.price {
  color: $text-primary-color;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  @include font-2xl;
}

.grades {
  display: grid;
  gap: 20px;
}

.grade {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.05);
  padding: 20px;
  box-sizing: border-box;
}

.gradeHeader {
  display: grid;
  gap: 20px;
}

.imageContainer {
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.image {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

.loupe {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $primary-color;
  position: absolute;
  top: 10px;
  left: 10px;
}

.blankImage {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $element-background-secondary;
}

.gradePrice {
  width: 100%;
  height: 130px;
}

.gradeBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gradeName {
  text-transform: uppercase;
  font-weight: 600;
  color: $text-primary-color;
  @include font-2xl;
}

.gradeDescription {
  color: $input_text_color;
  @include font-lg;
}

.gradeFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    cursor: pointer;
    text-decoration: underline;
    color: $secondary-color;
    text-transform: uppercase;
    font-weight: 600;
    @include font-base;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $primary-color;
    }
  }
}

@include mobile-md {
  .markets {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include tablet {
  .markets {
    grid-template-columns: repeat(4, 1fr);
  }

  .gradeHeader {
    grid-template-columns: repeat(5, 1fr);
  }
}
