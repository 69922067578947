@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  position: relative;
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  box-sizing: border-box;
  flex-direction: column;
}

.content {
  width: 100%;
  max-width: $mobile-sm-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative;
  z-index: 1;
}

.content_title {
  position: relative;
  z-index: 1;
  padding: 40px 0;
}

.title {
  width: 100%;
  text-transform: uppercase;
  color: $text-secondary-color;
  font-weight: 300;
  @include font-3xl;
}

.subtitle {
  width: 100%;
  color: $text-secondary-color;
  font-weight: 300;
  @include font-lg;
  padding: 5px 0;
}

.registrationLink {
  color: $text-secondary-color !important;

  span {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input {
  label {
    color: $text-secondary-color;
  }
}

.forgottenLinkContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.forgottenLink {
  text-decoration: underline;
  text-transform: uppercase;
  color: $text-secondary-color !important;
  font-weight: 600;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: $background-fade;
  opacity: 0.9;
  pointer-events: none;
}

@include mobile-md {
  .content {
    max-width: $mobile-base-width;
  }
}
