@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  height: 100%;
  @include main-layout-adaptive-container;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  @include font-3xl;
}

.notes {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 20px;
  box-sizing: border-box;
  background: $text-secondary-color;
}

.note {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.noteTitle {
  text-transform: uppercase;
  font-weight: 600;
  @include font-lg;
}

.noteDescription {
  color: $input_text_color;
  font-weight: 400;
  @include font-lg;
}

.link {
  text-decoration: underline;
}

.subtitle {
  text-transform: uppercase;
  font-weight: 300;
  @include font-2xl;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.groupInputs {
  width: 100%;
  display: grid;
  gap: 20px;
}

.buttonContainer {
  width: 100%;
}

@include tablet {
  .title {
    @include font-4xl;
  }

  .subtitle {
    @include font-3xl;
  }

  .groupInputs {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }

  .buttonContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}
